<template>
    <div>
        <div v-for="(orderItem, orderIndex) in orderNumber.deliveryFailed"
             :key="orderIndex"
             :title="orderNumber.deliveryFailed[0].orderNum"
             style="margin: 5px 0;background:#fff;"
        >
            <div class="d-flex font-sm"
                 style="height: 60px;border-left: 4px solid #009B4C;"
                 @click="changeClickItem(orderIndex)">
                <div class="d-flex a-center j-center h-100" style="width: 40px;">
                                    <span v-if="orderItem.orderStatus == 2"
                                          class="iconStyle">
                                        <img :src="iconImg.tsb" style="width:20px;height:20px;">
                                    </span>
                </div>
                <div class="d-flex a-center j-start h-100 font-bold"
                     style="width: 240px;color: #009B4C;">
                    <span style="margin-left: 10px;">{{ orderItem.orderNum }}</span>
                </div>
                <div class="d-flex a-center j-start h-100" style="width: 180px;">
                    <span v-if="orderItem.orderStatus != 0">{{ orderItem.orderList[0].date }}</span>
                </div>
                <div class="d-flex a-center j-start h-100" style="width: 240px;">
                                    <span v-if="orderItem.orderStatus != 0">
                                        {{ orderItem.orderList[0].status}}，{{ orderItem.orderList[0].address }}
                                    </span>
                </div>
                <div class="d-flex a-center j-end h-100" style="width: 100px;">
                    <span v-if="orderItem.orderStatus == 2" style="margin-left: 10px;">{{ $t('language.deliveryFailed') }}</span>
                    <span v-if="orderItem.countryCode" style="margin-left: 10px;">
                                        {{ orderItem.countryCode}}
                                    </span>
                </div>
                <div class="d-flex j-end h-100 flex-1 p-r-md">
                    <div style="width: 60px;" class="cursor-pointer d-flex j-end a-center h-100">
                        <img :src="iconImg.jt"
                             :class="clickItem === orderIndex ? 'transformJt' : ''"
                             style="width:15px;height:15px;transform: rotate(-135deg)"
                        >
                    </div>
                </div>
            </div>
            <div v-if="orderItem.orderStatus != 0 && clickItem === orderIndex"
                 style="border-top: 1px solid #ccc;padding: 20px;width:990px;margin: 0 auto;">
                <track-route :orderStatus="orderItem.statusCode" class="m-b-md" />
                <div class="p-w-lg pos-r">
                    <div class="pos-a"
                         style="border-left: 1px solid #ccc; top: 10px;left: 29px;bottom: 10px;"></div>
                    <div class="pos-r" style="z-index:10;">
                        <div v-for="(item, index) in orderItem.orderList" :key="index"
                             class="text-left m-b-sm font-sm"
                             style="margin-left: -5px;">
                          <div class="font-bold">
                            <span class="d-inline-block m-r-md"
                                  :style="{'background': index===0?'#056F34':'#ccc'}"
                                  style="width:10px;height:10px;"></span>
                            <span :style="{'color': index===0?'#056F34':'#66667D'}">
                                {{ item.date }}；{{ item.status }}，{{ item.address }}
                            </span>
                          </div>
                          <div class="m-l-xl">{{ item.remark }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import trackRoute from '@/views/trajectory/com/trackRoute';
    export default {
        name: 'deliveryFailed',
        components: {
            trackRoute
        },
        props: {
            orderNumber: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            iconImg: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                clickItem: 1000
            }
        },
        created() {

        },
        methods: {
            //            是否显示详情
            changeClickItem(type) {
                if (this.clickItem === type) {
                    this.clickItem = 1000;
                } else {
                    this.clickItem = type;
                }
            }
        },
        watch: {}
    }

</script>
<style scoped>

</style>