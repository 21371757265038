<template>
    <div>
        <div class="w-100 b pos-r" style="height:200px;" :style="{'background': 'url(' + routeImg + ')'}">
            <div class="w-100 h-100 pos-a top-0 left-0 right-0 bottom-0 container-box">
                <div class="h-100 pos-r w-100 fuji">
                    <div class="wei-da">
                        <div class="d-inline-block pos-a" style="left: 2%;top: 57%;">
                            <div class="font-xs font-white status-title">{{ $t('language.forecast') }}</div>
                            <div class="status-bo"></div>
                        </div>
                        <div class="d-inline-block pos-a" style="left: 30%;top: 18%;">
                            <div class="font-xs font-white status-title">{{ $t('language.airTakeOff') }}</div>
                            <div class="status-bo"></div>
                        </div>
                        <div class="d-inline-block pos-a"  style="right: 30%;top: 18%;">
                            <div class="font-xs font-white status-title">{{ $t('language.arrivalAtDestinationAirport') }}</div>
                            <div class="status-bo"></div>
                        </div>
                        <div class="d-inline-block pos-a" style="right: 2%;top: 57%;">
                            <div class="font-xs font-white status-title">{{ $t('language.delivered') }}</div>
                            <div class="status-bo"></div>
                        </div>
                    </div>
                    <div class="pos-a top-0 left-0 w-100 h-100">
                        <div :style="{'width':orderStatus == 0?'50px':(orderStatus == 1?'320px': (orderStatus == 2?'610px': '100%'))}"
                             style="overflow: hidden;background:rgba(255,255,255,0.7);"
                             class="h-100">
                            <div class="yi-da">
                                <div class="d-inline-block pos-a" style="left: 2%;top: 57%;">
                                    <div style="padding: 5px 10px;border-radius: 3px;background:rgba(0,0,0,0.7);"
                                         class="font-xs font-white">{{ $t('language.forecast') }}</div>
                                    <div class="status-bo"></div>
                                    <div class="pos-r" style="margin-top: -10px;">
                                        <div v-if="orderStatus == 0" class="max-yuan"></div>
                                        <div class="pos-a min-yuan"></div>
                                    </div>
                                </div>
                                <!--<div v-if="orderStatus == 5 || orderStatus == 3" class="d-inline-block pos-a" style="left: 16%;top: 30%;">-->
                                    <!--<div style="padding: 5px 10px;border-radius: 3px;background:rgba(0,0,0,0.7);"-->
                                         <!--class="font-xs font-white">{{ $t('language.duringTransportation') }}</div>-->
                                    <!--<div class="status-bo"></div>-->
                                    <!--<div class="pos-r" style="margin-top: -10px;">-->
                                        <!--<div v-if="orderStatus == 1" class="max-yuan"></div>-->
                                        <!--<div class="pos-a min-yuan"></div>-->
                                    <!--</div>-->
                                <!--</div>-->
                                <div v-if="orderStatus > 0" class="d-inline-block pos-a"  style="left: 30%;top: 18%;">
                                    <div style="padding: 5px 10px;border-radius: 3px;background:rgba(0,0,0,0.7);"
                                         class="font-xs font-white">{{ $t('language.airTakeOff') }}</div>
                                    <div class="status-bo"></div>
                                    <div class="pos-r" style="margin-top: -10px;">
                                        <div v-if="orderStatus == 1" class="max-yuan"></div>
                                        <div class="pos-a min-yuan"></div>
                                    </div>
                                </div>
                                <div v-if="orderStatus > 1" class="d-inline-block pos-a" style="right: 30%;top: 18%;">
                                    <div style="padding: 5px 10px;border-radius: 3px;background:rgba(0,0,0,0.7);"
                                         class="font-xs font-white">{{ $t('language.arrivalAtDestinationAirport') }}</div>
                                    <div class="status-bo"></div>
                                    <div class="pos-r" style="margin-top: -10px;">
                                        <div v-if="orderStatus == 2" class="max-yuan"></div>
                                        <div class="pos-a min-yuan"></div>
                                    </div>
                                </div>
                                <div v-if="orderStatus > 2" class="d-inline-block pos-a" style="right: 2%;top: 57%;">
                                    <div style="padding: 5px 10px;border-radius: 3px;background:rgba(0,0,0,0.7);"
                                         class="font-xs font-white">{{ $t('language.delivered') }}</div>
                                    <div class="status-bo"></div>
                                    <div class="pos-r" style="margin-top: -10px;">
                                        <div v-if="orderStatus == 3" class="max-yuan"></div>
                                        <div class="pos-a min-yuan"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import routeImg from '@/assets/image/trajectory/guijiimg.png';
    export default {
        name: 'trackRoute',
        props: {
            orderStatus: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                routeImg: routeImg
            }
        },
        created() {
            console.log(this.orderStatus, 'orderStatus');
        },
        methods: {},
        watch: {}
    }

</script>
<style scoped>
    .fuji:before{
        content: '';
        display: table;
    }
    .container-box{
        background: rgba(170,165,165,0.5);
        overflow: hidden
    }
    .wei-da {
        width:1230px;
        height:600px;
        border: 2px solid #eee;
        border-radius: 50%;
        margin-top: 80px;
        margin-left: -142px
    }
    .yi-da{
        width:1230px;
        height:600px;
        border: 2px solid #DD312A;
        border-radius: 50%;
        margin-top: 80px;
        margin-left: -142px
    }
    .status-title{
        padding: 5px 10px;
        border-radius: 3px;
        background:rgba(0,0,0,0.3);
    }
    .status-bo{
        width:2px;
        height:30px;
        border-left:1px solid #333;
        margin: 0 auto;
    }
    .max-yuan{
        width: 18px;
        height:18px;
        border-radius: 50%;
        background:rgba(221,49,42,0.3);
        margin:0 auto;
    }
    .min-yuan{
        width:10px;
        height:10px;
        border-radius:50%;
        background:rgba(221,49,42,1);
        top: 4px;
        left:calc(50% - 5px);
    }
</style>