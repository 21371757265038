import axios from 'axios';
import { strRSA } from '@/utils/RSA_util';
const serviceTrackQuery = axios.create({
    baseURL: process.env.VUE_APP_TRACK_QUERY,
    timeout: 30000
});
function getTime(newDate) {
    // const newDate = new Date();
    const y = newDate.getFullYear();
    const m = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
    const d = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
    const h = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
    const mm = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
    const s = newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds();
    const tokenDate = y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
    return tokenDate;
}
const getCurrentZoneTime = function(zone){
    const timezone = zone; //目标时区
    const offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
    const nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
    const targetDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);  //当前东八区的时间

    // console.log(targetDate, '==targetDate');
    // const current = targetDate.getTime();  //当前时区时间戳
    // console.log(current)
    return getTime(targetDate);
}
// 轨迹查询
export const getTrackQueryData = (orderNum) => {
    return serviceTrackQuery({
        method: 'GET',
        url: '/open/website/track?trackNo=' + orderNum,
        headers: {
            token: strRSA(getCurrentZoneTime(8))
        }
    });
};
