<template>
    <div style="background:#F2F2F2;">
        <div style="background:#fff;max-width:1920px;margin:0 auto;">
            <div style="height: 80px;">
                <div class="d-flex h-100" style="border-bottom: 1px solid #ccc;">
                    <div class="flex-1 h-100 d-flex a-center p-l-lg text-left">
                        <img :src="anJunImg" style="height: 60px" alt="安骏跨境物流">
                    </div>
                    <div class="d-flex a-center text-right p-r-lg">
                        <span v-if="hrefStr === -1 && $i18n.locale === 'en-US'"
                              style="color: #009B4C;"
                              @click="changeLanguage()"
                              class="m-r-md cursor-pointer font-sm">{{ $t('language.CN') }}</span>
                        <span v-if="hrefStr === -1 && $i18n.locale === 'zh-CN'"
                              style="color: #009B4C;"
                              @click="changeLanguage()"
                              class="m-r-md cursor-pointer font-sm">{{ $t('language.EN') }}</span>
                        <span class="headerBtn cursor-pointer" @click="toNewWindow(1)">{{ $t('language.returnToHome') }}</span>
                        <span class="headerBtn m-l-md cursor-pointer" @click="toNewWindow(2)">{{ $t('language.register') }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex p-md" style="background:#F2F2F2;height: calc(100vh - 160px);overflow-y: auto;">
                <div class="flex-1 p-r-md">
                    <div style="border-bottom: 1px solid #ccc;" class="text-left d-flex">
                        <ul class="flex-1">
                            <li class="clear-float cursor-pointer d-inline-block d-flex a-center"
                                :class="activeItem === 100 ? 'borderBtm': ''"
                                @click="changeStatus(100)">
                                <span class="float-l" style="height:20px;"></span>
                                <span class="m-l-xs float-l">{{ $t('language.allTracks') }}({{ orderNumber.orderAll.length }})</span>
                            </li>
                            <li class="clear-float cursor-pointer d-inline-block"
                                :class="activeItem === 0 ? 'borderBtm': ''"
                                @click="changeStatus(0)">
                                <img :src="iconImg.cbd" class="float-l" style="width:20px;height:20px;">
                                <span class="m-l-xs float-l" style="color:#707070;">{{ $t('language.queryNotFound') }}({{ orderNumber.notFound.length }})</span>
                            </li>
                            <li class="clear-float cursor-pointer d-inline-block"
                                :class="activeItem === 5 ? 'borderBtm': ''"
                                @click="changeStatus(5)">
                                <img :src="iconImg.yuz" class="float-l" style="width:20px;height:20px;">
                                <span class="float-l m-l-xs" style="color:#1296DB;">{{ $t('language.duringTransportation') }}({{ orderNumber.inTransit.length }})</span>
                            </li>
                            <li class="clear-float cursor-pointer d-inline-block"
                                :class="activeItem === 1 ? 'borderBtm': ''"
                                @click="changeStatus(1)">
                                <img :src="iconImg.ddq" class="float-l" style="width:20px;height:20px;">
                                <span class="float-l m-l-xs" style="color:#17267D;">{{ $t('language.download') }}({{ orderNumber.arriveCollect.length }})</span>
                            </li>
                            <li class="clear-float cursor-pointer d-inline-block"
                                :class="activeItem === 2 ? 'borderBtm': ''"
                                @click="changeStatus(2)">
                                <img :src="iconImg.tsb" class="float-l" style="width:20px;height:20px;">
                                <span class="float-l m-l-xs" style="color:#FF0033;">{{ $t('language.deliveryFailed') }}({{ orderNumber.deliveryFailed.length }})</span>
                            </li>
                            <li class="clear-float cursor-pointer d-inline-block"
                                :class="activeItem === 3 ? 'borderBtm': ''"
                                @click="changeStatus(3)">
                                <img :src="iconImg.yqs" class="float-l" style="width:20px;height:20px;">
                                <span class="float-l m-l-xs" style="color:#05C465;">{{ $t('language.signedIn') }}({{ orderNumber.successfulSignIn.length }})</span>
                            </li>
                        </ul>
                        <div style="font-size: 14px;font-weight: bold;color:#056F34;" class="text-right m-r-sm">
                            {{ $t('language.customerService') }}QQ：4009996128
                        </div>
                    </div>
                    <div class="p-h-md">
                        <div v-if="activeItem === 100">
                            <all-trajectory :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 0">
                            <can-not-find :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 1">
                            <wait-extract :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 2">
                            <delivery-failed :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 3">
                            <signed-in :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                        <div v-if="activeItem === 5">
                            <in-transit :orderNumber="orderNumber" :iconImg="iconImg" />
                        </div>
                    </div>
                </div>
                <div style="width: 300px;">
                    <textarea
                        rows="20"
                        v-model="orderContainer"
                        class="w-100"
                        :placeholder="$t('language.placeholder')"></textarea>
                    <div style="color: #f00;text-align: left;">{{ $t('language.beCareful') }}：{{ $t('language.upToQueries') }}</div>
                    <div class="d-flex a-center j-center font-white font-bold m-t-sm cursor-pointer"
                         style="height: 40px;background:#009B4C;border-radius: 5px;font-size: 18px;"
                         @click="queryOrder()">
                        <span :style="{'letter-spacing': $i18n.locale === 'zh-CN' ? '10px' : 0}">{{ $t('language.submit') }}</span>
                    </div>
                    <div class="m-t-sm">
                        <img :src="kuaJin" class="w-100">
                    </div>
                </div>
            </div>
            <div style="height: 80px;background:#009B4C;"
                 class="font-sm font-white d-flex flex-column a-center j-center">
                <div>@ Copyright 2017 szanjuntrack.Com Rights Reserved</div>
              <div>
                <span>{{ $t('language.anjun') }}</span>
                <a class="m-l-sm" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2021129290号</a>
                <a class="m-l-sm" target="_blank" href="https://www.beian.gov.cn">京网备44030702004669</a>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    import anjun from '@/assets/image/trajectory/u25.png';
    import logo from '@/assets/image/trajectory/logo.png';
    import logoPu from '@/assets/image/trajectory/logo_pu.png';
    import kuajing from '@/assets/image/trajectory/u241.png';
    import cbd from '@/assets/image/trajectory/u122.png';
    import yqs from '@/assets/image/trajectory/u140.png';
    import yuz from '@/assets/image/trajectory/u188.png';
    import ddq from '@/assets/image/trajectory/u156.png';
    import tsb from '@/assets/image/trajectory/u172.png';
    import jt from '@/assets/image/trajectory/u120.png';
    import { getTrackQueryData } from '@/views/trajectory/trajectoryApi';
    import allTrajectory from '@/views/trajectory/com/allTrajectory';
    import canNotFind from '@/views/trajectory/com/canNotFind';
    import waitExtract from '@/views/trajectory/com/waitExtract';
    import deliveryFailed from '@/views/trajectory/com/deliveryFailed';
    import signedIn from '@/views/trajectory/com/signedIn';
    import inTransit from '@/views/trajectory/com/inTransit';
    export default {
        name: '',
        components: {
            allTrajectory,
            canNotFind,
            waitExtract,
            deliveryFailed,
            signedIn,
            inTransit
        },
        data() {
            return {
                anJunImg: anjun,
                kuaJin: kuajing,
                iconImg: {
                    cbd: cbd,
                    yuz: yuz,
                    yqs: yqs,
                    ddq: ddq,
                    tsb: tsb,
                    jt: jt
                },
                activeItem: 100,
                activeName: '',
                orderNumber: {
                    orderAll: [],
                    notFound: [],
                    inTransit: [],
                    arriveCollect: [],
                    deliveryFailed: [],
                    successfulSignIn: [],
                    itMayBeAbnormal: []
                },
                orderContainer: '',
                isClick: true,
                orderNumArr: [],
                hrefStr: ''
            }
        },
        created() {
            this.hrefStr = window.location.href.indexOf('pu');
            if (this.hrefStr !== -1) {
              this.$i18n.locale = 'pu-PT';
              this.anJunImg = logoPu;
            }
            if (this.$route.params.orderNum) {
                this.orderContainer = this.$route.params.orderNum;
                this.queryOrder();
            }
        },
        methods: {
//            状态切换
            changeStatus(type) {
                this.activeItem = type;
            },
//            获取查询订单数量
            getQueryNumber() {
                const orderNum = this.orderContainer.split(/[(\r\n)\r\n]+/);
                this.orderNumArr = [];
                for (let j = 0; j < orderNum.length; j++) {
                    if (orderNum[j] !== '') {
                        const orderNumber = orderNum[j].trim();
                        if (this.orderNumArr.indexOf(orderNumber) === -1) {
                            this.orderNumArr.push(orderNumber.trim());
                        }
                    }
                }
            },
//            获取轨迹
            queryOrder() {
                if (this.isClick === true) {
                    this.isClick = false;
                    this.orderNumber.orderAll = [];
                    this.orderNumber.arriveCollect = [];
                    this.orderNumber.deliveryFailed = [];
                    this.orderNumber.inTransit = [];
                    this.orderNumber.notFound = [];
                    this.orderNumber.itMayBeAbnormal = [];
                    this.orderNumber.successfulSignIn = [];
                    this.getQueryNumber();

                    const dataArr = [];
                    this.orderNumArr.map((item, index) => {
                        if (index < 50) {
                            dataArr.push(item);
                        }
                    });
//                    将数组内元素分为多个小数组
                    const group = (array, subGroupLength) => {
                        let index = 0;
                        let newArray = [];
                        while(index < array.length) {
                            newArray.push(array.slice(index, index += subGroupLength));
                        }
                        return newArray;
                    };

                    const groupArr = group(dataArr, 10);

                    const getArr = (groupArr) => {
                        let map = [];
                        let index = 0;
                        if (index < groupArr.length) {
                            for (let j = 0; j < groupArr[index].length; j++) {
                                map.push(new Promise((resolve) => {
                                    let orderNo = groupArr[index][j];
                                    getTrackQueryData(groupArr[index][j]).then(res => {
                                        const b = (JSON.stringify(res.data.extra) === '{}');
                                        if (b === true) {
                                            res.data.extra['trackNo'] = orderNo;
                                        }
                                        return resolve(res);
                                    });
                                }));
                            }
                            index++;
                        }
                        const time = setInterval(() => {
                            if (index < groupArr.length) {
                                for (let j = 0; j < groupArr[index].length; j++) {
                                    let orderNo = groupArr[index][j];
                                    map.push(new Promise((resolve, reject) => {
                                        getTrackQueryData(groupArr[index][j]).then(res => {
                                            const b = (JSON.stringify(res.data.extra) === '{}');
                                            if (b === true) {
                                                res.data.extra['trackNo'] = orderNo;
                                            }
                                            return resolve(res);
                                        }).catch(() => {
                                            reject()
                                        });
                                    }));
                                }
                                index++;
                            } else {
                                Promise.all(map).then(data => {
                                    data.map(item => {
                                        if (item.status === 200) {
                                            if (item.data.code === 200) {
                                                const order = item.data.data;
                                                const orderDetail = order; // JSON.parse(order);
                                                orderDetail.orderNum = item.data.extra.trackNo;
                                                orderDetail.countryCode = item.data.extra.countryCode;
                                                orderDetail.orderList = [];
                                                if (orderDetail.length > 1) {
                                                    let t = 0;
                                                    for (let j = 0; j < orderDetail.length; j++) {
                                                        for (let k = 0; k < orderDetail.length; k++) {
                                                            if (new Date(orderDetail[j].date).getTime() < new Date(orderDetail[k].date).getTime()) {
                                                                t = orderDetail[k];
                                                                orderDetail[k] = orderDetail[j];
                                                                orderDetail[j] = t;
                                                            }
                                                        }
                                                    }
                                                }
                                                orderDetail.map(item => {
                                                    orderDetail.orderList.unshift(item);
                                                });
                                                if (orderDetail[orderDetail.length - 1].stateCode === 'leave_orgin_place'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'forecast'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'arrive_at_warehouse'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'leave_warehouse') {
                                                    orderDetail.statusCode = 0
                                                } else if (orderDetail[orderDetail.length - 1].stateCode === 'transport'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'air_take_off'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'arrived_at_airline') {
                                                    orderDetail.statusCode = 1
                                                } else if (
                                                    orderDetail[orderDetail.length - 1].stateCode === 'customs_buckle'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'tax_failure'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'customs_paid'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'tax_to_be_paid'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'clearance_successful'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'clearance_start'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'clearance_failed'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'customs_pay_tax'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'customs_detention'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'arrival_at_destination_airport'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'send_back'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'abnormal'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'lost'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'reassign'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'destroy'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'parcel_damaged'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'return_to_sender'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'delivering'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'post_office_of_destination_country'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'arrive_to_collect'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'delivery_failed'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'in_delivery'
                                                    || orderDetail[orderDetail.length - 1].stateCode === 'try_to_deliver'
                                                ) {
                                                    orderDetail.statusCode = 2
                                                } else if (
                                                    // orderDetail[orderDetail.length - 1].stateCode === 'parcel_damaged'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'return_to_sender'
                                                    // // || orderDetail[orderDetail.length - 1].stateCode === 'delivering'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'post_office_of_destination_country'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'arrive_to_collect'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'delivery_failed'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'in_delivery'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'try_to_deliver'
                                                    orderDetail[orderDetail.length - 1].stateCode === 'delivered'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'send_back'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'abnormal'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'lost'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'reassign'
                                                    // || orderDetail[orderDetail.length - 1].stateCode === 'destroy'
                                                ) {
                                                    orderDetail.statusCode = 3
                                                } else if (orderDetail[orderDetail.length - 1].stateCode === 'delivering') {
                                                    const newArr = JSON.parse(JSON.stringify(orderDetail));
                                                    newArr.reverse();
                                                    for (let i = 0; i < newArr.length; i++) {
                                                        if (newArr[i].stateCode !== 'delivering') {
                                                            if (newArr[i].stateCode === 'leave_orgin_place'
                                                                || newArr[i].stateCode === 'forecast'
                                                                || newArr[i].stateCode === 'arrive_at_warehouse'
                                                                || newArr[i].stateCode === 'leave_warehouse') {
                                                                orderDetail.statusCode = 0
                                                            } else if (newArr[i].stateCode === 'transport'
                                                                || newArr[i].stateCode === 'air_take_off'
                                                                || newArr[i].stateCode === 'arrived_at_airline') {
                                                                orderDetail.statusCode = 1
                                                            } else if (
                                                                newArr[i].stateCode === 'customs_buckle'
                                                                || newArr[i].stateCode === 'tax_failure'
                                                                || newArr[i].stateCode === 'customs_paid'
                                                                || newArr[i].stateCode === 'tax_to_be_paid'
                                                                || newArr[i].stateCode === 'clearance_successful'
                                                                || newArr[i].stateCode === 'clearance_start'
                                                                || newArr[i].stateCode === 'clearance_failed'
                                                                || newArr[i].stateCode === 'customs_pay_tax'
                                                                || newArr[i].stateCode === 'customs_detention'
                                                                || newArr[i].stateCode === 'arrival_at_destination_airport'
                                                                || newArr[i].stateCode === 'parcel_damaged'
                                                                || newArr[i].stateCode === 'return_to_sender'
                                                                || newArr[i].stateCode === 'post_office_of_destination_country'
                                                                || newArr[i].stateCode === 'arrive_to_collect'
                                                                || newArr[i].stateCode === 'delivery_failed'
                                                                || newArr[i].stateCode === 'in_delivery'
                                                                || newArr[i].stateCode === 'try_to_deliver'
                                                                || newArr[i].stateCode === 'send_back'
                                                                || newArr[i].stateCode === 'abnormal'
                                                                || newArr[i].stateCode === 'lost'
                                                                || newArr[i].stateCode === 'reassign'
                                                                || newArr[i].stateCode === 'destroy'
                                                            ) {
                                                                orderDetail.statusCode = 2
                                                            } else if (
                                                                // newArr[i].stateCode === 'parcel_damaged'
                                                                // || newArr[i].stateCode === 'return_to_sender'
                                                                // || newArr[i].stateCode === 'post_office_of_destination_country'
                                                                // || newArr[i].stateCode === 'arrive_to_collect'
                                                                // || newArr[i].stateCode === 'delivery_failed'
                                                                // || newArr[i].stateCode === 'in_delivery'
                                                                // || newArr[i].stateCode === 'try_to_deliver'
                                                                newArr[i].stateCode === 'delivered'
                                                                // || newArr[i].stateCode === 'send_back'
                                                                // || newArr[i].stateCode === 'abnormal'
                                                                // || newArr[i].stateCode === 'lost'
                                                                // || newArr[i].stateCode === 'reassign'
                                                                // || newArr[i].stateCode === 'destroy'
                                                            ) {
                                                                orderDetail.statusCode = 3
                                                            }
                                                            break;
                                                        }
                                                    }
                                                }

                                                if (orderDetail[orderDetail.length - 1].stateCode === 'arrive_to_collect') {
                                                    orderDetail.orderStatus = 1;
                                                    this.orderNumber.arriveCollect.push(orderDetail); // 到达待取
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else if (orderDetail[orderDetail.length - 1].stateCode === 'delivery_failed') {
                                                    orderDetail.orderStatus = 2;
                                                    this.orderNumber.deliveryFailed.push(orderDetail); // 投递失败
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else if (orderDetail[orderDetail.length - 1].stateCode === 'delivered') {
                                                    orderDetail.orderStatus = 3;
                                                    this.orderNumber.successfulSignIn.push(orderDetail); // 已签收
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else if (orderDetail[orderDetail.length - 1].stateCode === 'may_be_abnormal') {
                                                    orderDetail.orderStatus = 4;
                                                    this.orderNumber.itMayBeAbnormal.push(orderDetail); // 可能异常
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                } else {
                                                    orderDetail.orderStatus = 5;
                                                    this.orderNumber.inTransit.push(orderDetail); // 运输中
                                                    this.orderNumber.orderAll.push(orderDetail);
                                                }
                                            } else {
                                                const orderItem = {
                                                    orderNum: item.data.extra.trackNo,
                                                    orderStatus: 0
                                                };
                                                this.orderNumber.orderAll.push(orderItem);
                                                this.orderNumber.notFound.push(orderItem);
                                            }
                                        }
                                    });
                                    this.isClick = true;
                                });
                                clearInterval(time);
                            }
                        }, 1100);
                    };
                    getArr(groupArr);
                } else {
                    console.log('查询中')
                }
            },
//            前往其他页面
            toNewWindow(type) {
                if (type === 1) {
                    window.open('http://www.szanjun.com');
                } else {
                    window.open('http://aj.hushengkj.com/reg.asp');
                }
            },
//            切换语言
            changeLanguage() {
                this.$i18n.locale = this.$i18n.locale === 'zh-CN' ? 'en-US' : 'zh-CN';
                this.anJunImg = this.$i18n.locale === 'zh-CN' ? anjun : logo;
            }
        },
        watch: {}
    }

</script>
<style>
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .headerBtn {
        padding: 10px 30px;
        background: #009B4C;
        border-radius: 20px;
        color: #fff;
        font-size: 12px;
    }

    textarea {
        resize: none;
        border-color: #ccc;
        outline: none;
        padding: 10px;
        border-radius: 5px;
    }

    ul {
        list-style: none;
        height: 36px;
    }

    ul > li {
        display: inline-block;
        height: 36px;
        padding: 6px 10px;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 2px solid transparent;
    }

    li.borderBtm {
        border-bottom: 2px solid #008000;
    }

    .transformJt {
        transform: rotate(-45deg) !important;
        margin-top: -8px !important;
    }
</style>
